import React, { SVGProps } from 'react';
import cx from 'classnames';

import styles from './BaseIcon.module.scss';

export type BaseIconProps = SVGProps<SVGElement> & {
  spin?: boolean;
  rotate?: '90deg' | '-90deg';
};

function BaseIcon({
  spin,
  className,
  Icon,
  rotate,
  ...props
}: BaseIconProps & { Icon: React.FunctionComponent<React.SVGAttributes<SVGElement>> }) {
  const iconClasses = cx(
    { [styles.spin]: spin, [styles.rotate90]: rotate === '90deg', [styles.rotateMinus90]: rotate === '-90deg' },
    className,
  );
  return <Icon className={iconClasses} {...props} />;
}

export function createIconComponent(
  Icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>,
  opts?: { v2: boolean },
) {
  const options = { v2: false, ...opts };

  function BaseIconHoc(props: BaseIconProps) {
    return <BaseIcon Icon={Icon} {...props} />;
  }
  BaseIconHoc.v2 = options.v2;
  return BaseIconHoc;
}
